import "./App.css";
import { useMemo } from "react";

import Minter from "./Minter";

import * as anchor from "@project-serum/anchor";
import { clusterApiUrl } from "@solana/web3.js";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  getPhantomWallet,
  getSolflareWallet,
  getSolletWallet,
  getMathWallet,
} from "@solana/wallet-adapter-wallets";

import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";

import { WalletDialogProvider } from "@solana/wallet-adapter-material-ui";
import { ThemeProvider, createTheme } from "@material-ui/core";



const theme = createTheme({
  palette: {
    type: "dark",
  },
});

const candyMachineId_1 = process.env.REACT_APP_CANDY_MACHINE_ID_1
  ? new anchor.web3.PublicKey(process.env.REACT_APP_CANDY_MACHINE_ID_1)
  : undefined;

const candyMachineId_2 = process.env.REACT_APP_CANDY_MACHINE_ID_2
  ? new anchor.web3.PublicKey(process.env.REACT_APP_CANDY_MACHINE_ID_2)
  : undefined;

const candyMachineId_3 = process.env.REACT_APP_CANDY_MACHINE_ID_3
  ? new anchor.web3.PublicKey(process.env.REACT_APP_CANDY_MACHINE_ID_3)
  : undefined;

const network = process.env.REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork;

const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;
const connection = new anchor.web3.Connection(rpcHost);

const startDateSeed = parseInt(process.env.REACT_APP_CANDY_START_DATE!, 10);

const txTimeout = 30000; // milliseconds (confirm this works for your project)

const App = () => {
  const endpoint = useMemo(() => clusterApiUrl(network), []);

  const wallets = useMemo(
    () => [getPhantomWallet(), getSolflareWallet(), getSolletWallet(), getMathWallet() ],
    []
  );

  function toggleMenu() {
    const menu = document.getElementById("mobileNavContainer")!;
    menu.classList.toggle("open-menu");
    console.log("pressed");
  }

  return (
    <div>
      <div dangerouslySetInnerHTML={{ __html: `
        <video class="video-home" playsinline loop autoPlay muted autobuffer>
          <source src="/video/genmakers.mp4" type="video/mp4"/>
        </video>
      `}}></div>
            <div className='image-on-video'>
               <a href="https://www.genmakers.com/"><img src='/img/white_logo.png'/></a>
            </div>            
            <div className="mint-stack">
              <ThemeProvider theme={theme}>
                <ConnectionProvider endpoint={endpoint}>
                  <WalletProvider wallets={wallets} autoConnect>
                    <WalletDialogProvider>
                      
                        <Minter
                          candyMachineId={candyMachineId_1}
                          headerName={"Silver Pass"}
                          crossMintId={"dab7e222-074d-4a0f-9979-85d4cdcd2ac7"}
                          connection={connection}
                          startDate={startDateSeed}
                          txTimeout={txTimeout}
                          rpcHost={rpcHost}
                        />
                        <Minter
                          candyMachineId={candyMachineId_2}
                          headerName={"Gold Pass"}
                          crossMintId={"bc8dcbef-0f0d-4ccf-a0a4-6860885a58b1"}
                          connection={connection}
                          startDate={startDateSeed}
                          txTimeout={txTimeout}
                          rpcHost={rpcHost}
                        />
                        <Minter
                          candyMachineId={candyMachineId_3}
                          headerName={"Diamond Pass"}
                          crossMintId={"8e69d4b2-501f-4630-9548-a9460950b4bf"}
                          connection={connection}
                          startDate={startDateSeed}
                          txTimeout={txTimeout}
                          rpcHost={rpcHost}
                        />
                       
                      
                    </WalletDialogProvider>
                  </WalletProvider>
                </ConnectionProvider>
              </ThemeProvider>
            </div>
            <div className="socials">
              <a href="https://twitter.com/genmakersco/"><img className="social-icons" src="/icons/twitter.svg" alt="" /></a>
              <a href="https://discord.gg/ChauGMWpDA"><img className="social-icons" src="/icons/discord.svg" alt="" /></a>
            </div> 
          
    </div>
  );
};

export default App;
